









import { Component, Prop, Vue } from 'vue-property-decorator';
import { parseDateTime, timediff } from '@/utils/date';

@Component
export default class PostMetadata extends Vue {
  @Prop({ default: '' }) private classes!: string;

  @Prop({
    default: {
      Board: null,
      BoardID: 0,
      Body: 'loading...',
      CreatedAt: '0',
      DeletedAt: '0',
      Description: '',
      ID: 0,
      PostTags: [],
      Title: '',
      UpdatedAt: '0',
      Hits: 0,
    },
  }) private post!: any;

  // methods
  isUpdated(d1: string, d2: string) {
    return timediff(d2, d1) >= 60; // 1분 미만의 시간 내에 갱신했을 경우 갱신으로 안 침
  }

  parseTime(date: string) {
    return parseDateTime(date, 'YYYY. MM. DD.');
  }
}
