
















import { Component, Ref, Vue } from 'vue-property-decorator';
import { apireq } from '@/utils/apiRequest';
import PostMetadata from '@/components/PostMetadata.vue';
import { Viewer } from '@toast-ui/vue-editor';

@Component({
  components: {
    'post-metadata': PostMetadata,
  },
})
export default class Post extends Vue {
  // states
  post: myPost = {
    BoardID: 0,
    Body: '',
    CreatedAt: '0',
    DeletedAt: '0',
    Description: '',
    ID: 0,
    PostTags: [],
    Title: '',
    UpdatedAt: '0',
    Hits: 0,
  };

  loaded: boolean = false;

  @Ref() tuiViewer!: Viewer;

  tuiViewerHasLoaded() {
    this.loaded = true;

    if (this.post.Body) {
      this.tuiViewer.invoke('setMarkdown', this.post.Body);
    }
  }

  // methods
  toComments() {
    const commentEl = document.getElementById('comments');
    if (commentEl) {
      commentEl.scrollIntoView(false);
    }
  }

  // lifecycle
  created() {
    apireq('GET', `/post/${this.$route.params.post_id}?type=post`)
      .then((res) => {
        this.post = res.data.data;

        if (this.loaded) {
          this.tuiViewer.invoke('setMarkdown', this.post.Body);
        }
      });
  }
}
